<script>


import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	name: 'ContactDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'categoryId': String, 'auth':Object, 'secData':Object},
	emits: ["categoryId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			
			//form
			category:[]
		}
	},
	
	mounted:function(){
	
	},
	
	methods: {
	
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.categoryId);				
			if(this.categoryId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('categoryId',this.categoryId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContactCategories/getContactCategoryData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.category = response.data.category;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,categoryId){
			this.unlockContactCategory(categoryId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockContactCategory:function(categoryId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',categoryId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContactCategories/controlContactCategoryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		deleteContact:function(categoryId){
			
			if(categoryId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myContactCategory',JSON.stringify(this.category));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingContactCategories/controlContactCategoryDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.createMessageBox('success','Category',this.$t('msgCore.dataWasDeleted'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeleteContactCategory');
						}else if(response.data==0){
							self.createMessageBox('warning','Category',this.$t('msgCore.dataCantBeDeleted'));
							self.closeModalBoxSecure('myModalDeleteContactCategory');
						}else if(response.data==2){
							self.createMessageBox('danger','Category',this.$t('msgCore.dataWasNotDeleted'));
							self.closeModalBoxSecure('myModalDeleteContactCategory');
						}else if(response.data==3){
							self.createMessageBox('danger','Category',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalDeleteContactCategory');
						}
						self.category = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		}
	
		
	},
	
	watch: {
		categoryId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
	<div id="myModalDeleteContactCategory" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.DELETE_RECORD') }} <b><span>{{ category.name }}</span></b></h6>
					
				</div>
		
						<div v-if="accData.modulAcc && (auth.contactCategoryDelete)" class="modal-body">
						
							<div class="form-row">
							
								<div class="form-group col-md-2 col-12">
																																			
									<label><b>{{ $t('msgCore.ID') }}</b></label><br>
									{{category.id}}
								</div>
								
								<div class="form-group col-md-10 col-12">
																																			
									<label><b>{{ $t('msgCore.Name') }}</b></label><br>
									{{ category.name }} 
								</div>
								
							</div>	
									
								
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
								
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeleteContactCategory',category.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

								<button type="button" :disabled="(!auth.contactCategoryDelete) || !accData.modulAcc" v-on:click="deleteContact(category.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
							</div>	
							
						</div>
					
			</div>

		</div>
		
	</div>
	
		
</template>


