<script>


import $ from 'jquery'; 

import Popper from "vue3-popper";


import ArticleSelectIntoPhoto from '@/components/Core/Articles/ArticleSelectIntoPhoto'
import ArticleSelectIntoPhotoSeo from '@/components/Core/Articles/ArticleSelectIntoPhotoSeo'

import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'


import Editor from '@tinymce/tinymce-vue'
//var Editor = require('@tinymce/tinymce-vue').default;



/* Import TinyMCE */
 import tinymce from 'tinymce';

 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';

 /* Import the skin */
 import 'tinymce/skins/ui/oxide/skin.css';

 /* Import plugins */
 import 'tinymce/plugins/advlist';
 import 'tinymce/plugins/code';
 import 'tinymce/plugins/emoticons';
 import 'tinymce/plugins/emoticons/js/emojis';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/table';

 /* Import premium plugins */
 /* NOTE: Download separately and add these to /src/plugins */
 /* import './plugins/checklist/plugin'; */
 /* import './plugins/powerpaste/plugin'; */
 /* import './plugins/powerpaste/js/wordimport'; */

 /* Import content css 
 import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
 import contentCss from 'tinymce/skins/content/default/content.css';
*/


//require('tinymce/tinymce');

import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

//import Datepicker from 'vue3-date-time-picker';
//import 'vue3-date-time-picker/dist/main.css'
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import tinymce from 'tinymce';
//import axios from 'axios';


//modal box preview photos to article


export default defineComponent ({
	name: 'ArticleEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Datepicker
		,'editor': Editor
		,ArticleSelectIntoPhoto
		,ArticleSelectIntoPhotoSeo
		,Popper
		
	},
	props: {'articleId': String, 'auth':Object, 'secData':Object},
	emits: ["articleId","changeRecord"],
	data() {
		const TINYloadModalFormSelectPhotoToArticle = this.loadModalFormSelectPhotoToArticle;
		const TINYloadModalFormSelectGalleryToArticle = this.loadModalFormSelectGalleryToArticle;
		const TINYloadModalFormSelectGalleryToArticlePlugin = this.loadModalFormSelectGalleryToArticlePlugin;
		const TINYloadModalFormSelectLibraryToArticlePlugin = this.loadModalFormSelectLibraryToArticlePlugin;
		const TINYloadModalFormSelectDocumentToArticle = this.loadModalFormSelectDocumentToArticle;
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,

			publish_upOptions: {
				//locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				//useCurrent: true,
				//showClear: true,
				//showClose: true,
			},
			
			publish_downOptions: {
				//locale: 'sk',
				format: 'YYYY-MM-DD HH:mm',
				//useCurrent: true,
				//showClear: true,
				//showClose: true,
			},
			
			optionsAccessLevels: [],
			optionsCategories:[],
			optionsStates:[],
			optionsLanguages:[],
			
			initObj: {
				
				setup: function (editor) {
				
					editor.ui.registry.addButton('myPhotoButton', {
						text: 'Obrázok | text',
						icon: 'image',
						onAction: function () {
							TINYloadModalFormSelectPhotoToArticle();
						}
					});
					
					editor.ui.registry.addButton('myGalleryButton', {
						text: 'Galéria | text',
						icon: 'image',
						onAction: function () {
							TINYloadModalFormSelectGalleryToArticle();
						}
					});
					
					editor.ui.registry.addButton('myGalleryPluginButton', {
						text: 'Galéria | plugin',
						icon: 'image',
						onAction: function () {
							TINYloadModalFormSelectGalleryToArticlePlugin();
						}
					});
					
					editor.ui.registry.addButton('myLibraryPluginButton', {
						text: 'Knižnica | plugin',
						icon: 'image',
						onAction: function () {
							TINYloadModalFormSelectLibraryToArticlePlugin();
						}
					});
					
					editor.ui.registry.addButton('myDocumentButton', {
						text: 'Dokument | text',
						icon: 'image',
						onAction: function () {
							TINYloadModalFormSelectDocumentToArticle();
						}
					});
					
				}
				,relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:450
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
			},
			
			/*initObjOneGallery: {
				setup: function (editor) {
					
				}
				,height:450
				,language:'sk'
			},*/
			
			
			//form
			article:[],
			gallery:[],
			library:[],
			docs:[],
			photos:[],
			optionsGalleries:[],
			optionsLibraries:[],
			selectedGallery:'',
			selectedLibrary:''
			//selectedArticleId:''
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.articleId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
						
			if(this.articleId=='null'){
				this.isNewRecord=true;
			}else if(this.articleId && this.articleId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('articleId',this.articleId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/getArticleData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.article = response.data.article;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsCategories = response.data.optionsCategories;
				self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,articleId){
			this.unlockArticle(articleId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
								
			if(!this.article.title){this.article.titleError = true; }else{this.article.titleError = false;}
			if(!this.article.alias){this.article.aliasError = true; }else{this.article.aliasError = false;}
			if(!this.article.category){this.article.categoryError = true; }else{this.article.categoryError = false;}
			if(!this.article.publish_up){this.article.publish_upError = true;}else{this.article.publish_upError = false;}
			if(!this.article.state){this.article.stateError = true; }else{this.article.stateError = false;}
			if(!this.article.language){this.article.languageError = true; }else{this.article.languageError = false;}
			if(!this.article.access){this.article.accessError = true; }else{this.article.accessError = false;}
			
			if(this.article.title && this.article.alias && this.article.category && this.article.state && this.article.language && this.article.access && this.article.publish_up){
				return true;
			}else{
				this.createMessageBox('warning','Article',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveArticle:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myArticle',JSON.stringify(this.article));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/controlArticleSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Article',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditArticle');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Article',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditArticle');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Article',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditArticle');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Article',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditArticle');
						}
						self.article = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockArticle:function(articleId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',articleId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/controlArticleUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		createAlias:function(){
				
				if(this.article.title && this.isNewRecord){
					
					var bodyFormData = new FormData();
					bodyFormData.set('title',this.article.title);
					
					axios({
						method: 'post',
						url: '/admins/app/core/Article/controlArticleCreateAlias.php',
						data: bodyFormData
					})
					.then(response => {
						if (response.data.error) {
								console.log('error', response.data.error)
						} else {
							//console.log('success2', response.data);
							this.article.alias = response.data.article.alias;
						}
					})
  
					.catch(error => {
						console.log(error.response)
					}); 
				}
			},
			
			resetIntroducePhoto:function(){
				this.article.photo = '';
				this.article.photoData = '';
			},

			resetIntroducePhotoSeo:function(){
				this.article.photoSeo = '';
				this.article.photoDataSeo = '';
			},

			//modal box article select photo
			loadModalFormAddIntroducePhoto:function(){
				this.cSession();
				console.log(this.articleId)
				//this.selectedArticleId=this.articleId;
				$('#myModalArticleAddIntroducePhotos').addClass('modal');
				$('#myModalArticleAddIntroducePhotos').modal({backdrop: "static"});
			},

			//modal box article select photoSeo
			loadModalFormAddIntroducePhotoSeo:function(){
				this.cSession();
				//console.log(this.articleId)
				//this.selectedArticleId=this.articleId;
				$('#myModalArticleAddIntroducePhotosSeo').addClass('modal');
				$('#myModalArticleAddIntroducePhotosSeo').modal({backdrop: "static"});
			},

			//natiahne udaje k vybranej fotke
			photoData:function(photoId){
			
				var bodyFormData = new FormData();
				bodyFormData.set('photoId',photoId);

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticlePhotoData.php',
					data: bodyFormData
				})
				.then(function (response) {
					//console.log(response.data);
					self.article.photoData = response.data.photoData;
					
				})
					.catch(function (error) {
					console.log(error);
				});
			},

			//natiahne udaje k vybranej Seo fotke
			photoDataSeo:function(photoId){
			
				var bodyFormData = new FormData();
				bodyFormData.set('photoId',photoId);

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticlePhotoData.php',
					data: bodyFormData
				})
				.then(function (response) {
					//console.log(response.data);
					self.article.photoDataSeo = response.data.photoData;
					
				})
					.catch(function (error) {
					console.log(error);
				});
			},
			
			//akcia po vybere fotky
			reloadAfterSelect(e) {
				//console.log(e);
				this.article.photo=e;
				this.photoData(e);
			},

			//akcia po vybere SEO fotky
			reloadAfterSelectSeo(e) {
				//console.log(e);
				this.article.photoSeo=e;
				this.photoDataSeo(e);
			},

			//modal box select photos to article
			loadModalFormSelectPhotoToArticle:function(){
				this.loadDefaultValuesPhotoToArticle();
				$('#myModalArticleSelectOnePhoto').addClass('modal');
				$('#myModalArticleSelectOnePhoto').modal({backdrop: "static"});
			},

			//modal box select gallery to article
			loadModalFormSelectGalleryToArticle:function(){
				this.loadDefaultValuesGalleryToArticle();
				$('#myModalArticleSelectOneGallery').addClass('modal');
				$('#myModalArticleSelectOneGallery').modal({backdrop: "static"});
				
			},

			//modal box select gallery to article Plugin
			loadModalFormSelectGalleryToArticlePlugin:function(){
				this.loadDefaultValuesGalleryToArticle();
				$('#myModalArticleSelectOneGalleryPlugin').addClass('modal');
				$('#myModalArticleSelectOneGalleryPlugin').modal({backdrop: "static"});
				
			},

			//modal box select library to article Plugin
			loadModalFormSelectLibraryToArticlePlugin:function(){
				this.loadDefaultValuesLibraryToArticle();
				$('#myModalArticleSelectOneLibraryPlugin').addClass('modal');
				$('#myModalArticleSelectOneLibraryPlugin').modal({backdrop: "static"});
				
			},

			//modal box select document to article
			loadModalFormSelectDocumentToArticle:function(){
				this.loadDefaultValuesDocumentToArticle();
				$('#myModalArticleSelectOneDocument').addClass('modal');
				$('#myModalArticleSelectOneDocument').modal({backdrop: "static"});
			},

			
		
			//tinymce select one photo
			//natiahne fotky pre vyber jednej do clanku
			loadDefaultValuesPhotoToArticle:function(){
				
				this.preloader=true
				
				var bodyFormData = new FormData();
				bodyFormData.set('galleryId',this.selectedGallery);
			
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleIntroductionPhotosData.php',
					data: bodyFormData
				})
				.then(function (response) {
				
					//console.log(response.data);
					self.accData = response.data.accData;
					
					//hide loader
					self.preloader=false
					
					self.gallery = response.data.gallery;
					self.photos = response.data.photos;
					self.optionsGalleries = response.data.optionsGalleries;
			
				})
					.catch(function (error) {
					console.log(error);
					
					
				});
			
			},

			//zapise vybranu fotku do clanku
			loadPhotoToArticleUrl:function(photoId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('photoId',photoId);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getPhotoDataToArticle.php',
					data: bodyFormData
				})
				.then(response => {
					//console.log(response.data);
					var currData='<a class="fancybox" data-fancybox="gallery" href="'+response.data.photoUrl+'"><img class="img-fluid" src="' + response.data.photoUrlThumb + '" /></a>';
					tinymce.activeEditor.insertContent(currData);
					self.closeModalBoxSecureHelper('myModalArticleSelectOnePhoto',null);
				})
				.catch(function (error) {
					console.log(error);
				});
			
			},


			//tinymce select one gallery
			//natiahne galerie pre vyber jednej do clanku
			loadDefaultValuesGalleryToArticle:function(){
				
				this.preloader=true
				
				var bodyFormData = new FormData();
				bodyFormData.set('galleryId',this.selectedGallery);
			
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleGalleriesData.php',
					data: bodyFormData
				})
				.then(function (response) {
				
					//console.log(response.data);
				
					//hide loader
					self.preloader=false
					
					self.optionsGalleries = response.data.optionsGalleries;
			
				})
					.catch(function (error) {
					console.log(error);
					
					
				});
			
			},

			//zapise vybranu galeriu do clanku
			loadGalleryToArticleUrl:function(){
				
				var bodyFormData = new FormData();
				bodyFormData.set('galleryId',this.selectedGallery);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleGalleryData.php',
					data: bodyFormData
				})
				.then(response => {
					//console.log(response.data);
					var x = "";
					for(var i=0;i<response.data.photos.length;i++){
						x +="<div class='crop_gallery2'><a class='fancybox' data-fancybox='gallery' href='"+response.data.photos[i].photoUrl+"'><img src='" + response.data.photos[i].photoUrlThumb + "'/></a></div>";
					}
					tinymce.activeEditor.insertContent(x);
					self.closeModalBoxSecureHelper('myModalArticleSelectOneGallery',null);
				})
				.catch(function (error) {
					console.log(error);
				});
			
			},

			//tinymce select one gallery plugin
			//zapise vybranu galeriu do clanku
			loadGalleryToArticlePlugin:function(){
				
				if(this.selectedGallery){
					
					var x = "";
					x +="{loadPhotoGallery["+this.selectedGallery+"]}";
				
					tinymce.activeEditor.insertContent(x);
					this.closeModalBoxSecureHelper('myModalArticleSelectOneGalleryPlugin',null);
					
				}
			},

			//tinymce select one library
			//natiahne kniznice pre vyber jednej do clanku
			loadDefaultValuesLibraryToArticle:function(){
				
				this.preloader=true
				
				var bodyFormData = new FormData();
				bodyFormData.set('libraryId',this.selectedLibrary);
			
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleLibrariesData.php',
					data: bodyFormData
				})
				.then(function (response) {
				
					//console.log(response.data);
				
					//hide loader
					self.preloader=false
					
					self.optionsLibraries = response.data.optionsLibraries;
			
				})
					.catch(function (error) {
					console.log(error);
					
					
				});
			
			},
			
			//zapise vybranu kniznicu do clanku
			loadLibraryToArticlePlugin:function(){
				
				if(this.selectedLibrary){
					
					var x = "";
					x +="{loadDocumentLibrary["+this.selectedLibrary+"]}";
				
					tinymce.activeEditor.insertContent(x);
					this.closeModalBoxSecureHelper('myModalArticleSelectOneLibraryPlugin',null);
					
				}
			},


			//tinymce select one document
			//natiahne dokumenty pre vyber jedneho do clanku
			loadDefaultValuesDocumentToArticle:function(){
				
				this.preloader=true
				
				var bodyFormData = new FormData();
				bodyFormData.set('libraryId',this.selectedLibrary);
			
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleDocumentsData.php',
					data: bodyFormData
				})
				.then(function (response) {
				
					//console.log(response.data);
					self.accData = response.data.accData;
					
					//hide loader
					self.preloader=false
					
					self.library = response.data.library;
					self.docs = response.data.docs;
					self.optionsLibraries = response.data.optionsLibraries;

				})
					.catch(function (error) {
					console.log(error);
					
					
				});
			
			},

			//zapise vybrany dokument do clanku
			loadDocumentToArticleUrl:function(documentId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('documentId',documentId);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/getArticleDocumentData.php',
					data: bodyFormData
				})
				.then(response => {
					//console.log(response.data);
					var currData='<a href='+response.data.myDocument.url+'>'+response.data.myDocument.title+'</a>';
					tinymce.activeEditor.insertContent(currData);
					self.closeModalBoxSecureHelper('myModalArticleSelectOneDocument',null);
				})
				.catch(function (error) {
					console.log(error);
				});
			
			},
		
	},
	
	watch: {
		articleId() {
			this.loadDefaultValues();
		},
		'article.title': function () {
			this.createAlias();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditArticle" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Article') }} <span v-if="!article.isNewRecord"><b>{{ article.title }}</b> id: {{article.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.articlesNew || auth.articlesEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="article.isCheckedOut && secData.userId != article.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':article.isCheckedOut}"><i class="fa" :class="{'angle-warning':article.isCheckedOut, 'fa-exclamation-triangle':article.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="article.isCheckedOut" :content="article.isCheckedOutName+', '+article.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:article.isCheckedOutColor}">{{ article.isCheckedOutLetterName+''+article.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>
							
								<div class="row">
									
									<div class="col-md-8 col-12">
								
										<ul class="nav nav-tabs" role="tablist">
							
											<li class="nav-item">
												<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
											</li>
											
											<li class="nav-item">
												<a class="nav-link" id="menu1-tab" data-toggle="tab" href="#menu1" role="tab" aria-controls="menu1" aria-selected="false">Úvodný obrázok</a>
											</li>

											<li class="nav-item">
												<a class="nav-link" id="menu11-tab" data-toggle="tab" href="#menu11" role="tab" aria-controls="menu11" aria-selected="false">SEO obrázok</a>
											</li>
											
											<li class="nav-item">
												<a class="nav-link" id="menu2-tab" data-toggle="tab" href="#menu2" role="tab" aria-controls="menu2" aria-selected="false">Úvodný text</a>
											</li>
											
											<li class="nav-item">
												<a class="nav-link" id="menu3-tab" data-toggle="tab" href="#menu3" role="tab" aria-controls="menu3" aria-selected="false">Hlavný text</a>
											</li>

											<li class="nav-item">
												<a class="nav-link" id="menu4-tab" data-toggle="tab" href="#menu4" role="tab" aria-controls="menu4" aria-selected="false">Špeciálne parametre</a>
											</li>
											
										</ul>

										<div class="tab-content" id="myTabContent">
								
											<div id="home" class="tab-pane fade show active" role="tabpanel">
															
									
												<div class="form-row">
													
																									
													<div class="form-group col-md-5 col-12">
														<label :class="{'text-danger':article.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
														<input type="text" class="form-control form-control-sm" :class="{'is-invalid':article.titleError}" v-on:change="checkSave()" v-model="article.title" >
														<div v-show="article.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
													
													<div class="form-group col-md-5 col-12">
														<label :class="{'text-danger':article.aliasError}"><b>{{ $t('msgCore.alias') }}</b> </label>
														<input type="text" :disabled="!isNewRecord" class="form-control form-control-sm" v-on:change="checkSave()" :class="{'is-invalid':article.aliasError}"  v-model="article.alias" >
														<div v-show="article.aliasError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
													
													<div class="form-group col-md-12 col-12">
														<label><b>Podtitulok</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="article.subTitle" >
														
													</div>
													
													<div class="form-group col-md-12 col-12">
														<label><b>{{ $t('msgCore.external_url') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="article.external_url" >
														
													</div>
													
													<div class="form-group col-md-6 col-12">
														<label :class="{'text-danger':article.publish_upError}"><b>{{ $t('msgCore.publish_up') }}</b> </label>
														<Datepicker v-model="article.publish_up" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  @update:modelValue="checkSave()" :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" :class="{'is-invalid':article.publish_upError}"></Datepicker>
														<div v-show="article.publish_upError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
													
													<div class="form-group col-md-6 col-12">
														<label><b>{{ $t('msgCore.publish_down') }}</b> </label>
														
														<Datepicker v-model="article.publish_down" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" ></Datepicker>
													</div>
													
													
													<div class="form-group col-md-4 col-12">
														<label :class="{'text-danger':article.categoryError}"><b>{{ $t('msgCore.Category') }}</b></label>
																											
														<select v-model='article.category' class="form-control form-control-sm" :class="{'is-invalid':article.categoryError}" v-on:change="checkSave()">
															<option  value="">{{ $t('msgCore.selectCategory') }}</option>
															<option v-for="(options) in optionsCategories" :value="options.value" :key="options.value" >{{ options.title }}</option>
														</select>
														<div v-show="article.categoryError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectCategory')}}</span></div>
													</div>
													
													
													
													<div class="form-group col-md-4 col-12">
														<label><b>{{ $t('msgCore.Highlighted') }}</b></label>
																											
														<div class="togglebutton">
															<label>
																<input v-model='article.selected' type="checkbox">
																<span class="toggle"></span>
															</label>
														</div>
													</div>
													
													
													
													
												</div>	
											</div>
											
											<div id="menu1" class="tab-pane fade" role="tabpanel">
												
												<div class="form-row">
													<div  class="form-group col-md-3 col-12">
														<a class="fancybox" data-fancybox="gallery" :href="article.photoData"><img :src="article.photoData"></a>
													</div>
												</div>	

												<div class="form-row">	
													<div class="form-group col-md-3 col-12">
														<button type="button" :disabled="!auth.articlesAddPhoto" v-on:click="loadModalFormAddIntroducePhoto()" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnSelectPhoto') }}</button>
														
														<button v-if="article.photo" type="button" :disabled="!auth.articlesAddPhoto" v-on:click="resetIntroducePhoto()" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-minus"></i> {{ $t('msgCore.btnStorno') }}</button>
													</div>
												</div>
												
											</div>

											<div id="menu11" class="tab-pane fade" role="tabpanel">
												
												<div class="form-row">
													<div  class="form-group col-md-3 col-12">
														<a class="fancybox" data-fancybox="gallery" :href="article.photoDataSeo"><img :src="article.photoDataSeo"></a>
													</div>
												</div>	

												<div class="form-row">	
													<div class="form-group col-md-3 col-12">
														<button type="button" :disabled="!auth.articlesAddPhoto" v-on:click="loadModalFormAddIntroducePhotoSeo()" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnSelectPhoto') }}</button>
														
														<button v-if="article.photoSeo" type="button" :disabled="!auth.articlesAddPhoto" v-on:click="resetIntroducePhotoSeo()" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-minus"></i> {{ $t('msgCore.btnStorno') }}</button>
													</div>
												</div>
												
											</div>
											
											
											<div id="menu2" class="tab-pane fade" role="tabpanel">
												<editor  plugins="wordcount, advlist, autolink, link, image, lists, charmap, print, preview, hr, anchor, pagebreak, spellchecker, searchreplace, wordcount, visualblocks, visualchars, code, fullscreen, insertdatetime, media, nonbreaking, save, table, directionality, emoticons, template, paste" v-model="article.short_text" :init="initObj" class="form-control form-control-sm"  />
											</div>
											
											<div id="menu3" class="tab-pane fade" role="tabpanel">
												<editor  plugins="wordcount, advlist, autolink, link, image, lists, charmap, print, preview, hr, anchor, pagebreak, spellchecker, searchreplace, wordcount, visualblocks, visualchars, code, fullscreen, insertdatetime, media, nonbreaking, save, table, directionality, emoticons, template, paste" v-model="article.full_text" :init="initObj" toolbar="alignjustify aligncenter myPhotoButton myGalleryButton myGalleryPluginButton myLibraryPluginButton myDocumentButton" class="form-control form-control-sm" />
											</div>
											
											<div id="menu4" class="tab-pane fade" role="tabpanel">
											
												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label><b>Farba pozadia titulku</b> </label>
														<input type="color" class="form-control form-control-sm" v-model="article.color_background_title" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label><b>HEX tvar</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="article.color_background_title" >
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label><b>Farba textu titulku</b> </label>
														<input type="color" class="form-control form-control-sm" v-model="article.color_text_title" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label><b>HEX tvar</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="article.color_text_title" >
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-12">
														<label><b>Brand logo k článku</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="article.url_logo_brand" >
													</div>

													
												</div>

											</div>

										</div>
										
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
											
										<div class="form-row">
										
											<div class="form-group col-12">
												<label :class="{'text-danger':article.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																									
												<select v-model='article.access' class="form-control form-control-sm " :class="{'is-invalid':article.accessError}" v-on:change="checkSave()">
													<option value="">{{ $t('msgCore.selectLevel') }}</option>
													<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="article.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':article.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
												
												<div  v-for="(option) in optionsStates" :key="option.value" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='article.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
															<span class="check"></span>
														</span>
													</label>
												</div>
												<div v-show="article.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':article.languageError}"><b>{{ $t('msgCore.Language') }}</b></label>
																									
												<select v-model='article.language' class="form-control form-control-sm" :class="{'is-invalid':article.languageError}" v-on:change="checkSave()" >
													<option  value="">{{ $t('msgCore.selectLanguage') }}</option>
													<option v-for="(option) in optionsLanguages" :value="option.value" :key="option" >{{ $t('msgCore.'+option.title) }}</option>
												</select>
												<div v-show="article.languageError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLanguage')}}</span></div>
											</div>
										
										</div>
										
									</div>										
											
									
									
									
									
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditArticle',article.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="!auth.articlesEdit || !accData.modulAcc" v-on:click="saveArticle()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	<ArticleSelectIntoPhoto :auth="auth" :secData="secData" :articlePhotoId="String(article.photo)" @changeAfterSelect="reloadAfterSelect"></ArticleSelectIntoPhoto>

	<ArticleSelectIntoPhotoSeo :auth="auth" :secData="secData" :articlePhotoId="String(article.photoSeo)" @changeAfterSelect="reloadAfterSelectSeo"></ArticleSelectIntoPhotoSeo>
		

	<div id="myModalArticleSelectOnePhoto" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">

			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <b><span>{{ gallery.title }}</span></b></h6>
				</div>
				
				<div class="modal-body">
					<div class="form-group col-md-4 col-12">
						<label><b>{{ $t('msgCore.Gallery') }}</b></label>
						<select v-model='selectedGallery' class="form-control form-control-sm" v-on:change="loadDefaultValuesPhotoToArticle()" >
							<option  value="">{{ $t('msgCore.selectGallery') }}</option>
							<option v-for="(option) in optionsGalleries" :value="option.value" :key="option">{{ option.title }}</option>
						</select>
					</div>

					<div class="row">
						<div class="col-12">
							<div v-for="photo in photos" class="crop_gallery2" :key="photo">
								<img :src="photo.fotoData" :title="photo.title">
								<button type="button" v-on:click="loadPhotoToArticleUrl(photo.id)" :title="$t('msgCore.btnSelectPhoto')" id="myModalBtn" class="btn btn-primary btn-sm" style="position:absolute; bottom:0; right:0;"><i class="smire-icon fas fa-check"></i> url</button>
							</div>
						</div>
					</div>						
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleSelectOnePhoto',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>

	<div id="myModalArticleSelectOneGallery" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Gallery') }}</h6>
				</div>
				
				<div class="modal-body">
					
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<select v-model='selectedGallery' class="form-control form-control-sm" v-on:change="loadDefaultValuesGalleryToArticle()">
								<option  value="">{{ $t('msgCore.selectGallery') }}</option>
								<option v-for="(option) in optionsGalleries" :value="option.value" :key="option">{{ option.title }}</option>
							</select>
							<button type="button" :title="$t('msgCore.btnSend')" class="btn btn-info btn-sm my-btn" v-on:click="loadGalleryToArticleUrl()">
								Odoslať
							</button>
						</div>
					</div>
									
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleSelectOneGallery',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>

	<div id="myModalArticleSelectOneGalleryPlugin" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Gallery') }}</h6>
				</div>
				
				<div class="modal-body">
					
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<select v-model='selectedGallery' class="form-control form-control-sm" v-on:change="loadDefaultValuesGalleryToArticle()">
								<option  value="">{{ $t('msgCore.selectGallery') }}</option>
								<option v-for="(option) in optionsGalleries" :value="option.value" :key="option">{{ option.title }}</option>
							</select>
							<button type="button" :title="$t('msgCore.btnSend')" class="btn btn-info btn-sm my-btn" v-on:click="loadGalleryToArticlePlugin()">
								Odoslať
							</button>
						</div>
					</div>
									
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleSelectOneGalleryPlugin',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>


	<div id="myModalArticleSelectOneLibraryPlugin" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Library') }}</h6>
				</div>
				
				<div class="modal-body">
					
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<select v-model='selectedLibrary' class="form-control form-control-sm" v-on:change="loadDefaultValuesLibraryToArticle()">
								<option  value="">{{ $t('msgCore.selectLibrary') }}</option>
								<option v-for="(option) in optionsLibraries" :value="option.value" :key="option">{{ option.title }}</option>
							</select>
							<button type="button" :title="$t('msgCore.btnSend')" class="btn btn-info btn-sm my-btn" v-on:click="loadLibraryToArticlePlugin()">
								Odoslať
							</button>
						</div>
					</div>
									
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleSelectOneLibraryPlugin',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>

			</div>

		</div>
		
	</div>


	<div id="myModalArticleSelectOneDocument" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <b><span>{{ library.title }}</span></b></h6>
				</div>
				
				<div class="modal-body">
				
					<div class="form-group col-md-4 col-12">
						<label><b>{{ $t('msgCore.Library') }}</b></label>
						<select v-model='selectedLibrary' class="form-control form-control-sm" v-on:change="loadDefaultValuesDocumentToArticle()">
							<option  value="">{{ $t('msgCore.selectLibrary') }}</option>
							<option v-for="(option) in optionsLibraries" :value="option.value" :key="option">{{ option.title }}</option>
						</select>
					</div>

					<div class="row">
						<div class="col-12">
							<table class="table table-sm table-hover">
							
								<tr v-for="doc in docs" :key="doc.id">
									<td>{{doc.title}}</td>
									<td><button type="button" v-on:click="loadDocumentToArticleUrl(doc.id)" :title="$t('msgCore.btnSelectDocument')" id="myModalBtn" class="btn btn-primary btn-sm" style="">vložiť</button></td>
								</tr>
							
							</table>
						</div>
					</div>						
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleSelectOneDocument',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>


</template>


