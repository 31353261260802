<script>

export default {
	name: 'MainLogo',
	
	components: {
	
	},
	
	data() {
		return {
			
		}
	},
	
	props: {'params': Object},
	
	mounted:function(){
		
	},
	
	methods: {
			
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>
		<div class='container-fluid'>
			<div class='row'>
				<div class='col-8'>
					<img class='img-fluid' :src="'/'+ params.appFolder + 'images/smire_logo_white.svg'">
				</div>
			</div>
				
		</div>
	</div>
</template>


