<script>

import Popper from "vue3-popper";

 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';

 /* Import the skin */
 import 'tinymce/skins/ui/oxide/skin.css';

 /* Import plugins */
 import 'tinymce/plugins/advlist';
 import 'tinymce/plugins/code';
 import 'tinymce/plugins/emoticons';
 import 'tinymce/plugins/emoticons/js/emojis';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/table';


import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	name: 'ContactEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		
		Popper
	},
	props: {'categoryId': String, 'auth':Object, 'secData':Object},
	emits: ["categoryId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsCategories:[],
			optionsStates:[],
			optionsLanguages:[],
			
			initObj: {
				
				relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:450
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
			},
			
						
			//form
			category:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.categoryId)		
			if(this.categoryId=='null'){
				this.isNewRecord=true;
			}else if(this.categoryId && this.categoryId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('categoryId',this.categoryId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContactCategories/getContactCategoryData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.category = response.data.category;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsCategories = response.data.optionsCategories;

				//self.optionsTemplates = response.data.optionsTemplates;
				//self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,categoryId){
			this.unlockContactCategory(categoryId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
								
			if(!this.category.name){this.category.nameError = true; }else{this.category.nameError = false;}
			if(!this.category.access){this.category.accessError = true; }else{this.category.accessError = false;}
			
			if(!this.category.nameError && this.category.access){
				return true;
			}else{
				this.createMessageBox('warning','Contact',this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveContact:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myContactCategory',JSON.stringify(this.category));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingContactCategories/controlContactCategorySave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBox('success','Category',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditContactCategory');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Category',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditContactCategory');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Category',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditContactCategory');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Category',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditContactCategory');
						}
						self.category = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockContactCategory:function(categoryId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',categoryId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContactCategories/controlContactCategoryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

	},
	
	watch: {
		categoryId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditContactCategory" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.ContactCategories') }} <span v-if="!category.isNewRecord"><b>{{ category.title }}</b> id: {{category.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.categoryCategoryNew || auth.contactCategoryEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="category.isCheckedOut && secData.userId != category.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':category.isCheckedOut}"><i class="fa" :class="{'angle-warning':category.isCheckedOut, 'fa-exclamation-triangle':category.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="category.isCheckedOut" :content="category.isCheckedOutName+', '+category.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:category.isCheckedOutColor}">{{ category.isCheckedOutLetterName+''+category.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>
							
								<div class="row">
									
									<div class="col-md-8 col-12">
								
										<ul class="nav nav-tabs" role="tablist">
							
											<li class="nav-item">
												<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
											</li>
											
											
											
										</ul>

										<div class="tab-content" id="myTabContent">
								
											<div id="home" class="tab-pane fade show active" role="tabpanel">
															
									
												<div class="form-row">
																								
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Name') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="category.name" >
														
													</div>
													
													
												</div>

											</div>
											
												
										</div>
										
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
											
										<div class="form-row">
										
											<div class="form-group col-12">
												<label :class="{'text-danger':category.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																									
												<select v-model='category.access' class="form-control form-control-sm " :class="{'is-invalid':category.accessError}" v-on:change="checkSave()">
													<option value="">{{ $t('msgCore.selectLevel') }}</option>
													<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="category.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':category.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
												
												<div  v-for="(option) in optionsStates" :key="option.value" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='category.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
															<span class="check"></span>
														</span>
													</label>
												</div>
												<div v-show="category.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
											</div>
											
											
										
										</div>
										
									</div>										
																		
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditContactCategory',category.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="!auth.contactCategoryEdit || !accData.modulAcc" v-on:click="saveContact()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


