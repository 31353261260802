
//sk
import skDataCore from './languages/sk/msgCore.json'
import skDataCustomMailing from './languages/sk/msgCustomMailing.json'
const skData = {msgCore:skDataCore, msgCustomMailing:skDataCustomMailing};


import enData from './languages/en.json'
import czData from './languages/cz.json'
import deData from './languages/de.json'
export const defaultLocale = 'sk'
export const languages = {
 
  sk: skData,
  en: enData.en,
  cz: czData.cz,
  de: deData.de
}