<script>

import $ from 'jquery'; 


import axios from 'axios';

import UploadPhoto from '@/components/Core/Articles/uploadPhoto/uploadPhoto'

	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'


export default defineComponent ({
	name: 'ArticleEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		UploadPhoto
	},
	props: {'articlePhotoId': String, 'auth':Object, 'secData':Object},
	emits: ["articlePhotoId","changeAfterSelect"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
						
			optionsGalleries:[],
			selectedGallery:'',

			//form
			gallery:[],
			photos:[]
		}
	},
	
	mounted:function(){
		
		
		//this.loadDefaultValues();
		//console.log(this.articleId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			var bodyFormData = new FormData();
			bodyFormData.set('galleryId',this.selectedGallery);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/getArticleIntroductionPhotosData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.gallery = response.data.gallery;
				self.photos = response.data.photos;
				self.optionsGalleries = response.data.optionsGalleries;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		//modal box upload photos
		loadModalFormUploadPhoto:function(){
			//this.loadDefaultValuesPhotoToArticle();
			$('#myModalUploadPhotos').addClass('modal');
			$('#myModalUploadPhotos').modal({backdrop: "static"});
		},
		
		closeModalBoxSecureHelper:function(myform){
			this.closeModalBoxSecure(myform);
		},
		
		//vybratie fotky
		selectPhoto:function(photoId){
			this.$emit('changeAfterSelect', photoId)
			this.closeModalBoxSecureHelper('myModalArticleAddIntroducePhotosSeo',null);
		},

		reloadAfterAddedPhoto(e) {
			console.log(e);
			this.loadDefaultValues();
			//this.selectedArticleId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},
		
	},
	
	watch: {
		articlePhotoId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalArticleAddIntroducePhotosSeo" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <b><span>{{ gallery.title }}</span></b></h6>
				</div>
				
				<div class="modal-body">
				
					<div class="form-group col-md-4 col-12">
						<label><b>{{ $t('msgCore.Gallery') }}</b></label>
						<div class="input-group">
						<select v-model='selectedGallery' class="form-control form-control-sm" v-on:change="loadDefaultValues()">
							<option  value="">{{ $t('msgCore.selectGallery') }}</option>
							<option v-for="(option) in optionsGalleries" :value="option.value" :key="option">{{ option.title }}</option>
						</select>
						<button :disabled="!auth.photosUpload || !selectedGallery" type="button" v-on:click="loadModalFormUploadPhoto()" :title="$t('msgCore.btnUpload')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }} novú fotku</button>
						</div>
					</div>
					
					<div class="row">
						<div class="col-12">
							<div v-for="photo in photos" class="crop_gallery2" :key="photo">
								<img :src="photo.fotoData" :title="photo.title">
								<button type="button" v-on:click="selectPhoto(photo.id)" :title="$t('msgCore.btnSelectPhoto')" id="myModalBtn" class="btn btn-primary btn-sm" style="position:absolute; bottom:0; right:0;"><i class="smire-icon fas fa-check"></i></button>
							</div>
						</div>
					</div>						
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalArticleAddIntroducePhotosSeo')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

						
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>
	
	<UploadPhoto :auth="auth" :galleryId="String(selectedGallery)" @changeAfterUpload="reloadAfterAddedPhoto"></UploadPhoto>

</template>


