<script>

import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'MailingListEmail',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		MyPagination
		,MyCountRecords
        //,GroupAuthorizations
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
			accData:[],
           
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedCategoryId:'',

            countPages:0,
			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
						
		
			filterExtended:false,
			showFilter:false,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
	
	},
	
	methods: {
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/customMailing/MailingListEmail/getBasicList.php',{
				params:{
					filterText: this.selectedFilterText,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideArticles';
			
				self.countPages = response.data.countPages;

				self.basicDataCount = response.data.basicDataCount;
				self.basicData = response.data.basicData;
			
		
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
	
		
		//systemove funkcie k zoznamu
		
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		}

		// systemove funkcie k zoznamu KONIEC



				
	},	
	
			
	watch: {
		'auth.sysLogsList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
                        <div class="row">
                            <div class="col-6">
                                <h5>{{ $t('msgCustomMailing.ListEmails') }}</h5>	 
                            </div> 
                            
                            <div class="col-6 right">
                                
                               
                            </div> 
                        </div>

                        <!-- filters basic -->
                        <div class="row">
                            <div class="input-group col-md-4 col-12">
                                <button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
                                    <span v-if="filterExtended">
                                        <i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
                                        <i v-else class="smire-icon fas fa-search-minus"></i>
                                    </span>
                                    <span v-else>
                                        <i class="smire-icon fas fa-search"></i> 
                                    </span>
                                    {{ $t('msgCore.btnFilter') }}
                                </button>
                                <input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
                                <button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
                                    <i class="smire-icon fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        

                        <!-- filters extended -->
						<div v-show="showFilter && filterExtended" class="row">
							<div class="col-12">
							
							</div>					
						</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
            <div v-if="basicDataCount>0">
						
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-sm">
							
								<thead>
									<tr>
										
										<th class="orderBy" width="100" v-on:click="sort('a.id')" :class="{ active: isActive('a.id'), asc: isAsc('a.id'), desc: isDesc('a.id')}">{{$t('msgCore.ID')}}</th>
										
										<th v-on:click="sort('a.created')">{{$t('msgCore.created')}}</th>
										
										<th>
											{{$t('msgCore.Sent')}}
										</th>

																				
										<th>
											{{$t('msgCore.Email')}}
										</th>

																				
										<th>
											{{$t('msgCustomMailing.Campaign')}}
										</th>
										
										<th>
											{{$t('msgCore.Contact')}}
										</th>
									
									</tr>
								</thead>
								
								
								
								<tbody v-for="(basicObj) in basicData" :key="basicObj" >
									<tr>
										<td>{{ basicObj.id }}</td>
										<td>{{ basicObj.createdView }}</td>
										
										<td>{{ basicObj.sentView }}</td>
										
										<td>{{ basicObj.email }}</td>

										
										
										<td>{{ basicObj.campaignName }}</td>	
										
										<td >
											{{ basicObj.contactName }}
										</td>
										
									</tr>
									
								</tbody>	

							
							</table>	
						</div>		
					
					</div>
				</div>

				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm" >Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
					</div>
				</div>	
								
			</div>	
            
            <div v-else class="row">
                <div class="col-12">
                    <div  class="alert alert-warning" role="alert">
                        {{$t('msgCore.forThisSelectExistAnyData')}}
                    </div>	
                </div>							
            </div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		
</template>


