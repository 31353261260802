<script>

import {ref} from 'vue'
//import $ from 'jquery'; 


import axios from 'axios';

import FileUpload from 'vue-upload-component'


	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'


export default defineComponent ({
	name: 'ContactImport',
	mixins: [ MixinGeneralFunct ],
	components: {
		FileUpload
	},
	props: {'auth':Object, 'secData':Object},
	emits: ["changeRecord"],

	setup(_,{emit}) {
		//console.log(props);
		const upload = ref(null)
		
		const files = ref([])
		function inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// 添加文件前
				// Filter system files or hide files
				// 过滤系统文件 和隐藏文件
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
				return prevent()
				}
				
				// Filter php html js file
				// 过滤 php html js 文件
				if (/\.(php?|php5?|html?|jsx?)$/i.test(newFile.name)) {
				return prevent()
				}
			}
		}

		function inputFile(newFile, oldFile) {
				//console.log('response', newFile.response)
				if (newFile && !oldFile) {
					// add
					//console.log('add', newFile)
					//console.log(newFile.response)
					newFile.sizeView=niceBytes(newFile.size);
				}
				if (newFile && oldFile) {
					// update
					//console.log('update', newFile)
					newFile.sizeView=niceBytes(newFile.size);
				}
				if (!newFile && oldFile) {
					// remove
					//console.log('remove', oldFile)
				}

				emit('changeRecord');
							
		}
		
		return {
			files,
			upload,
			inputFilter,
			inputFile,
		}

		function niceBytes(x){
			const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			let l = 0, n = parseInt(x, 10) || 0;
			while(n >= 1024 && ++l){
				n = n/1024;
			}
			return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
		}
	},

	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
						
			importedData:[],
			uploadDataCount:0,
			
			//form
			access:'10',
			accessError:false,

			optionsCategories:[],
			selectedCategoryId:''
			
		}
	},
	
	mounted:function(){
		
		this.preloader=false
		//this.loadDefaultValues();
		//console.log(this.articleId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDataFromImportedFile:function(file){
			
			var bodyFormData = new FormData();
			bodyFormData.set('file',file);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContacts/controlContactsLoadUploadFiles.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
			
				self.importedData = response.data.resDatas;
				self.uploadDataCount = response.data.uploadDataCount;
				self.optionsCategories = response.data.optionsCategories;
				
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		// load data form
		importContacts:function(){
			
			var bodyFormData = new FormData();
			bodyFormData.set('importedData',JSON.stringify(this.importedData));
			bodyFormData.set('categoryId',this.selectedCategoryId);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContacts/controlContactsImport.php',
				data: bodyFormData
			})
			.then(function (response) {
				//console.log(response.data);
				if(response.data.resCode==1){
					
					self.createMessageBox('success','Contact',self.$t('msgCore.dataWasSaved'));
					self.$emit('changeRecord', '')
				
					self.closeModalBoxSecure('myModalImportContacts');
				}
			//	self.importedData = response.data.resDatas;
				
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		closeModalBoxSecureHelper:function(myform){
			this.closeModalBoxSecure(myform);
			this.importedData=[];
			this.uploadDataCount=0;
			this.selectedCategoryId='';
			//self.$emit('changeRecord', '')
		}
		
	},
	
	watch: {
		
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalImportContacts" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Preview') }} <b><span></span></b></h6>
				</div>
				
				<div class="modal-body">

					<div class="row">
						<div class="col-12">
							<div class="alert alert-info" role="alert">
								format importovaného csv súboru:<br>
								spolocnost;meno;priezvisko;mobil;mobil2;mobil3;mobil4;mobil5;email;email2;email3;email4;email5;ulica;mesto;psc;adresa;web<br>
								kodovanie UTF-8<br>
								ak pole je prazdne, tak treba vynechať aj v súbore ;;;
							</div>
							
						</div>
					</div>
				
					<div class="row">
						<div class="col-12">
							<file-upload
							
							class="btn btn-primary btn-sm"
							post-action="/admins/app/customMailing/MailingContacts/controlContactsUpload.php"
							extensions="csv"
							accept="text/csv"
							:data="{access:this.access}"
							
							:multiple="false"
							
							v-model="files"
							@input-filter="inputFilter"
							@input-file="inputFile"
							ref="upload">
							
							1.vyberte csv súbor
							</file-upload>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<table class="table table-hover">
									<tr>
										<th>Názov</th>	
										<th>Typ</th>	
										<th>Veľkosť</th>	
										<th>Chyba</th>	
										<th>Progress</th>	
										<th>Stav nahratia</th>
										<th></th>
									</tr>
									<tr v-for="file in files" :key="file">
										<td>{{file.name}}</td>
										<td>{{file.type}}</td>
										<td>{{file.sizeView}}</td>
										<td>{{file.error}}</td>
										<td>
											<div class="progress" v-if="file.active || file.progress !== '0.00'">
											<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
											</div>
										</td>	
										<td><i v-if="file.success" class="fa fa-check text-success"></i><i v-if="!file.success" class="fa fa-times text-danger"></i></td>
										<td><span v-if="file.success" v-on:click="loadDataFromImportedFile(file.name)" class="btn btn-warning btn-sm">3.načítať údaje</span></td>
									</tr>
								
							</table>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<button type="button" class="btn btn-success btn-sm" v-if="(!$refs.upload || !$refs.upload.active)" @click.prevent="$refs.upload.active = true">
							2.začať nahrávať súbor
							</button>
					
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="table-responsive">
								<table class="table table-hover table-sm">
									
									<thead>
										<tr>
											<th>
												P.č. 
											</th>
											
											<th>
												Existencia záznamu
											</th>
											
											<th>
												Vhodný pre nahratie
											</th>

											<th>Kontakt ID</th>
											<th>{{$t('msgCore.Company')}}</th>
											
											<th>{{$t('msgCore.Firstname')}}</th>
											<th>{{$t('msgCore.Surname')}}</th>

											<th>{{$t('msgCore.Mobil')}}</th>
											<th>{{$t('msgCore.Mobil')}}2</th>
											<th>{{$t('msgCore.Mobil')}}3</th>
											<th>{{$t('msgCore.Mobil')}}4</th>
											<th>{{$t('msgCore.Mobil')}}5</th>

											<th>{{$t('msgCore.Email')}}</th>
											<th>{{$t('msgCore.Email')}}2</th>
											<th>{{$t('msgCore.Email')}}3</th>
											<th>{{$t('msgCore.Email')}}4</th>
											<th>{{$t('msgCore.Email')}}5</th>

											<th>{{$t('msgCore.Street')}}</th>
											<th>{{$t('msgCore.City')}}</th>
											<th>{{$t('msgCore.PSC')}}</th>
											<th>{{$t('msgCore.Address')}}</th>
											<th>{{$t('msgCore.Web')}}</th>
											
										
										</tr>
									</thead>
									
									
									<tr v-for="(item, index) in importedData" :key="item" >
										<td>{{index+1}}</td>
										
										<td class="align-middle">
											<i v-if="item.exists" class="fa fa-check green"></i>
											<i v-else class="fa fa-times red"></i>
										</td>
										
										<td class="align-middle">
											<i v-if="item.toImport" class="fa fa-check green"></i>
											<i v-else class="fa fa-times red"></i>
										</td>	

										<td>{{item.contactId}}</td>
										<td>{{item.company}}</td>
										<td>{{item.firstname}}</td>
								
										<td>{{item.surname}}</td>

										<td>{{item.mobil}}</td>
										<td>{{item.mobil2}}</td>
										<td>{{item.mobil3}}</td>
										<td>{{item.mobil4}}</td>
										<td>{{item.mobil5}}</td>

										<td>
											<i v-if="item.checkEmail" class="fa fa-check green"></i>
											<i v-else class="fa fa-times red"></i>
											{{item.email}}
										</td>

										<td>
											{{item.email2}}
										</td>
										<td>
											{{item.email3}}
										</td>
										<td>
											{{item.email4}}
										</td>
										<td>
											{{item.email5}}
										</td>
										
										<td>{{item.street}}</td>
										<td>{{item.city}}</td>
										<td>{{item.psc}}</td>
										<td>{{item.address}}</td>
										<td>{{item.web}}</td>
										
									</tr>
									
								</table>
							</div>
						</div>
					</div>

					<div class="row">
													
						<div class="form-group col-md-4 col-12">
							<label class="btn btn-info btn-sm">4. {{ $t('msgCore.Category') }} </label>								
							<select v-model='selectedCategoryId' class="form-control form-control-sm" >
								<option  value="">{{ $t('msgCore.selectCategory') }}</option>
								<option v-for="(options) in optionsCategories" :value="options.value" :key="options.value" >{{ options.title }}</option>
							</select>
							
						</div>
					
					</div>	


				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalImportContacts')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						<button v-if="uploadDataCount>0 && selectedCategoryId" type="button" v-on:click="importContacts()" class="btn btn-danger btn-sm">5. {{ $t('msgCore.Import') }}</button>
						
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>
	
	
</template>


